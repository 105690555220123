<template>
  <div>
    <b-card title="Photographs">
      <b-card-text>
        We have an extensive library of photographs from throughout the county.
      </b-card-text>
    </b-card>
    <br/>
  </div>
</template>

<script>
export default {
  name: 'Photographs'
}
</script>

<style scoped>

</style>
