<template>
  <div>
    <b-card title="Newspapers">
      <b-card-text>
        We have an extensive library of newspapers from throughout the county.
      </b-card-text>
    </b-card>
    <br/>
    <b-container fluid="true">
      <b-row cols="1" cols-sm="5">
        <b-col v-for="newspaper in this.newspapers" :key="newspaper.title">
          <b-card :title=newspaper.title :sub-title=newspaper.years :img-src="newspaper.img" :img-alt="newspaper.title"/>
          <br/>
        </b-col>
      </b-row>
    </b-container>
    <br/>
  </div>
</template>

<script>
export default {
  name: 'Newspapers',
  data () {
    return {
      newspapers: []
    }
  },
  mounted () {
    this.newspapers = [
      {
        title: 'Pierce County Hearld',
        years: '1890-1935, 1936-2000',
        img: 'img/collections/newspapers/pierce-county-hearld.jpg'
      },
      {
        title: 'Spring Valley Sun',
        years: '1890-1935, 1936-2000',
        img: 'img/collections/newspapers/spring-valley-sun.jpg'
      },
      {
        title: 'Elmwood Argus',
        years: '1890-1935, 1936-2000',
        img: 'img/collections/newspapers/elmwood-argus.jpg'
      },
      {
        title: 'Spring Valley/Elmwood Sun Argus',
        years: '1890-1935, 1936-2000',
        img: 'img/collections/newspapers/spring-valley-elmwood-sun-argus.jpg'
      },
      {
        title: 'Ellsworth Record',
        years: '1890-1935, 1936-2000',
        img: 'img/collections/newspapers/ellsworth-record.jpg'
      },
      {
        title: 'Maiden Rock Press',
        years: '1890-1935, 1936-2000',
        img: 'img/collections/newspapers/maiden-rock-press.jpg'
      },
      {
        title: 'Prescott Journal',
        years: '1890-1935, 1936-2000',
        img: 'img/collections/newspapers/prescott-journal.jpg'
      },
      {
        title: 'River Falls Journal',
        years: '1890-1935, 1936-2000',
        img: 'img/collections/newspapers/river-falls-journal.jpg'
      },
      {
        title: 'Red Wing Republican Eagle',
        years: '1890-1935, 1936-2000',
        img: 'img/collections/newspapers/red-wing-republican-eagle.jpg'
      },
      {
        title: 'Red Wing Republican',
        years: '1890-1935, 1936-2000',
        img: 'img/collections/newspapers/red-wing-republican.jpg'
      },
      {
        title: 'Belldenville Record',
        years: '1890-1935, 1936-2000',
        img: 'img/collections/newspapers/belldenville-record.jpg'
      },
      {
        title: 'River Falls Times',
        years: '1890-1935, 1936-2000',
        img: 'img/collections/newspapers/river-falls-times.jpg'
      },
      {
        title: 'Pierce Country Journal',
        years: '1890-1935, 1936-2000',
        img: 'img/collections/newspapers/pierce-country-journal.jpg'
      }
    ]
  }
}
</script>

<style scoped>

.card img {
  height: 50px;
  object-fit: cover;
}

</style>
